<template>
  <div id="app">
    <WhiteListMint />
  </div>
</template>

<script>
import WhiteListMint from "./components/WhiteListMint.vue";

export default {
  name: "App",
  components: {
    WhiteListMint,
  },
};
</script>
