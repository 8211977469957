<template>
    <div>
        <h1>Whitelist Mint</h1>
        <button @click="generateMerkleTree">Generate Merkle Tree</button>
        <button @click="mintToken">Mint Token</button>
        <button @click="mintTokenWhitelist"> Mint Token WhiteList</button>
    </div>
</template>
<script>
import { ethers } from "ethers";
import { MerkleTree } from "merkletreejs";
import MyToken from "../assets/MyToken.json";
import { Buffer } from "buffer";
const keccak256 = ethers.utils.keccak256;
export default {
    async created() {
        await this.loadFile();
    },
    methods: {

        async initEthers() {
            // MetaMaskがインストールされているか確認
            if (typeof window.ethereum === "undefined") {
                alert("MetaMaskが見つかりません。MetaMaskをインストールしてください。");
                return;
            }

            // Ethersの初期化
            this.provider = new ethers.providers.Web3Provider(window.ethereum);
            this.signer = this.provider.getSigner();
            this.contract = new ethers.Contract(MyToken.address, MyToken.abi, this.signer);
        },
        async loadFile() {
            try {
                const response = await fetch("/whitelist.txt");
                const data = await response.text();
                this.addresses = data.split("\n").map((line) => line.trim());
            } catch (error) {
                console.error("Error loading whitelist file:", error);
            }
        },
        async generateMerkleTree() {
            // 1. アドレスをハッシュ化して葉ノードを作成
            const leaves = this.addresses.map((address) => {
                return Buffer.from(ethers.utils.solidityKeccak256(["address"], [address]).slice(2), "hex");
            });

            // 2. 葉ノードを使ってマークルツリーを生成
            const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });

            // 3. マークルツリーのルートハッシュを取得
            const root = merkleTree.getHexRoot();

            // 4. 各葉ノードの証明を生成
            const proofs = leaves.map((leaf) => {
                const proof = merkleTree.getHexProof(leaf);
                return proof;
            });

            // 5. マークルツリーのルートハッシュ、葉ノード、証明をコンポーネントのデータに設定
            this.merkleRoot = root;
            console.log("マークルルート" + this.merkleRoot);
            this.merkleLeaves = leaves.map((leaf) => leaf.toString("hex"));
            this.merkleProofs = proofs;
        },
        async mintTokenWhitelist() {
            if (typeof window.ethereum === "undefined") {
                alert("MetaMaskがインストールされていません");
                return;
            }
            await this.initEthers();
            let index = await this.findAddressIndex(this.addresses);
            if (index === -1) {
                alert("MetaMaskアドレスがホワイトリストに存在しません");
                return;
            }
            const address = this.addresses[index];
            console.log("this.merkleProofs[index]:", this.merkleProofs[index]); // デバッグ用
            const proof = this.merkleProofs[index].map((proof) => {
                console.log("proof:", proof); // デバッグ用
                return ethers.utils.arrayify(proof);
            });
            console.log(typeof (proof));
            try {
                const result = await this.contract.mintWithMerkleProof(address, proof);
                console.log("Minted successfully:", result);
            } catch (error) {
                console.error("Error minting token:", error);
            }
        },

        async mintToken() {
            // MetaMaskがインストールされていることを確認
            if (typeof window.ethereum === "undefined") {
                alert("MetaMaskがインストールされていません");
                return;
            }

            await this.initEthers();

            // トークンを発行
            try {
                const tx = await this.contract.mintNft(window.ethereum.selectedAddress);
                await tx.wait();
                alert("トークンが正常に発行されました");
            } catch (error) {
                console.error("Error minting token:", error);
            }
        },
        async findAddressIndex(addressList) {
            const userAddress = window.ethereum.selectedAddress.toLowerCase();
            return addressList.findIndex(address => address.toLowerCase() === userAddress);
        },
    },
};
</script>